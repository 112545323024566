<template>
  <div class="payment_successful">
    <div class="payment_successful_title">{{ $t("SuccessfulTitle") }}</div>
    <div class="payment_successful_describe">
      {{ $t("SuccessfulDescribe") }}
    </div>
    <div class="payment_successful_form">
      <div class="payment_successful_form_item">
        <div class="payment_successful_form_label">{{ $t("email") }}</div>
        <div class="payment_successful_form_content">{{ email }}</div>
      </div>
      <div class="payment_successful_form_item">
        <div class="payment_successful_form_label">{{ $t("password") }}</div>
        <div class="payment_successful_form_content">
          <input
            @keydown="listenEnter"
            v-model="password"
            maxlength="32"
            :placeholder="$t('EnterYourPassword')"
            :type="close ? 'password' : 'text'"
            class="password_input"
          />
          <img
            @error="imageError"
            v-show="password"
            v-if="close"
            @click="close = false"
            :src="`${$store.state.assetsUrl}/imgs/login/close.webp`"
            alt="close button"
          />
          <img
            @error="imageError"
            v-show="password"
            v-else
            @click="close = true"
            :src="`${$store.state.assetsUrl}/imgs/login/open.webp`"
            alt="open button"
          />
        </div>
        <div class="tips">
          <div v-if="errorText" class="error_text">{{ errorText }}</div>
          <div v-if="password.length == 32" class="max_length">
            <span>32/32</span>
          </div>
        </div>
      </div>
      <div class="terms_and_privacy">
        <div>*</div>
        <span
          >{{ $t("AgreeTo") }}
          <i @click="$router.push('/terms/app')">{{ $t("user_agreement") }}</i>
          {{ $t("and") }}
          <i @click="$router.push('/privacy/app')">{{
            $t("privacy_policy")
          }}</i>
        </span>
      </div>
    </div>
    <div
      class="payment_successful_button"
      :class="{ can_not_next: password.length < 6 }"
      @click="goNext"
    >
      {{ $t("Next") }}
    </div>
    <loading :showCover="isLoading"></loading>
  </div>
</template>

<script>
import { changePassword } from "@/api/index.js";
import Vue from "vue";
import { Toast } from "vant";
import { myMixin } from "@/assets/mixin/index.js";
import { commonMixin } from "@/assets/mixin/common";
import Loading from "@/components/cover_loading.vue";
import { logFirebaseEvent } from "@/assets/log/log_manager.js";

Vue.use(Toast);
export default {
  name: "",
  components: { Loading },
  mixins: [myMixin, commonMixin],
  data() {
    return {
      email: "",
      password: "",
      close: true,
      errorText: "",
      isLoading: false,
    };
  },
  created() {
    this.$store.commit("setIsApp", true);
    // 初始化email数据
    this.initData();
    // 进入到输入密码页的埋点
    logFirebaseEvent("F_Web_Plan_Password");
  },
  methods: {
    // 监听按下enter键
    listenEnter(e) {
      if (e.keyCode == 13) {
        this.goNext();
        document.activeElement.blur();
      }
    },
    // 初始化email数据
    initData() {
      if (this.$store.state.signToken == "") return;
      this.email = this.$changeLocal.getItem("email") ?? "";
    },
    goNext() {
      if (this.password.length >= 6) {
        this.isLoading = true;
        changePassword({ password: this.password })
          .then((res) => {
            this.isLoading = false;
            if (res.errorCode == null) {
              Toast.success(this.$t("SuccessTip"));
              this.goPath("/how-to-use");
            } else {
              this.errorText = this.$t("pwd_error");
            }
          })
          .catch(() => {
            Toast(this.$t("GoPhoneToast"));
          });
      }
    },
  },
  watch: {
    password() {
      this.errorText = "";
    },
  },
};
</script>

<style scoped lang="less">
.payment_successful {
  padding: 0 20px;
  max-width: 765px;
  margin: 0 auto;

  .payment_successful_title {
    font-size: 24px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #212121;
    line-height: 33px;
    margin-top: 70px;
  }

  .payment_successful_describe {
    font-size: 12px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #999999;
    line-height: 13px;
    margin-top: 10px;
  }

  .payment_successful_form {
    margin-top: 56px;
    text-align: left;

    .payment_successful_form_item {
      margin-top: 30px;

      .tips {
        display: flex;

        .error_text {
          font-size: 12px;
          margin: 10px 0 0;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #e85345;
          line-height: 16px;
        }

        .max_length {
          position: relative;
          margin: 10px 0 0;
          flex: 1;
          height: 16px;

          span {
            position: absolute;
            right: 10px;
            font-size: 12px;
            font-family: "AvenirNext";
            font-weight: 500;
            color: #e3574b;
            line-height: 16px;
          }
        }
      }
    }

    .payment_successful_form_label {
      font-size: 15px;
      font-family: Helvetica;
      color: #999999;
      line-height: 18px;
    }

    .payment_successful_form_content {
      margin-top: 18px;
      font-size: 15px;
      font-family: Helvetica;
      color: #333333;
      line-height: 18px;
      position: relative;

      input {
        height: 52px;
        background: #f9f9f9;
        border-radius: 10px;
        border: 1px solid #dddddd;
        width: 100%;
        padding: 0px 16px;
        box-sizing: border-box;
        font-size: 15px;
        font-family: Helvetica;
        color: #333333;
        line-height: 18px;
      }

      input::-webkit-input-placeholder {
        color: #bbb;
        font-size: 14px;
      }

      img {
        cursor: pointer;
        position: absolute;
        width: 15px;
        height: 16px;
        right: 12px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }
  }

  .terms_and_privacy {
    display: flex;
    margin-top: 10px;

    div {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #bbbbbb;
      line-height: 19px;
      margin-right: 4px;
    }

    span {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #bbbbbb;
      line-height: 17px;

      i {
        font-style: normal;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .payment_successful_button {
    height: 55px;
    background: #fd7c61;
    border-radius: 55px;
    font-size: 16px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 200px auto 140px;
    cursor: pointer;
  }

  .can_not_next {
    opacity: 0.3;
  }
}
</style>
