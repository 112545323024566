export const myMixin = {
    data() {
        return {
            // 过期时间，单位为天
            expireTime: 2,
        }
    },
    created() {
        this.checkCookie();
    },
    methods: {
        checkCookie() {
            // 获取路由
            let fullPath = this.$route.fullPath;
            let step = this.$changeCookie.getCookie('step');
            // 当前是没有存储或者存储失效
            if (step == '' && fullPath != '/consultation') {
                // 清除数据
                this.clear();
            }
            // 如果当前页面和已经到达的页面不符就跳转
            if (fullPath != step) {
                // 重新定位到对应页面
                this.$router.push(step);
                // 重新设置过期时间
                this.$changeCookie.setCookie('step', step, this.expireTime);
            }
            // 如果token失效了，也跳转到注册页面，并清除
            let registerToken = this.$changeCookie.getCookie('registerToken');
            if (!registerToken && fullPath != '/consultation') {
                // 清除数据
                this.clear();
            }
        },
        clear() {
            // 清除数据
            localStorage.removeItem('localList');
            localStorage.removeItem('localPlan');
            this.$changeCookie.clearCookie('step');
            this.$changeCookie.clearCookie('registerToken');
            this.$store.commit('setSignToken', '');
            // 跳转到sign页面
            this.goPath('/consultation');
        },
        goPath(path) {
            this.$changeCookie.setCookie('step', path, this.expireTime);
            this.$router.push(path);
        }
    }
}